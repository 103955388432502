import {
  Container,
  Heading,
  useToast,
  Button,
  useDisclosure,
  Link,
  Progress,
  Text,
} from '@chakra-ui/react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import { FaSync, FaTrash } from 'react-icons/fa';
import React, { useState, useEffect } from "react";
import axios from 'axios';
import SuperModal from './components/Modal';

function App() {
  const toast = useToast()
  const [domains, setDomains] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [domainScan, setDomainScan] = useState('');
  const [isScan, setIsScan] = useState(false);

  useEffect(() => {
    const fetchDomain = async () => {
      axios.get(`${process.env.REACT_APP_API_URL}/api/domain`)
        .then((res) => {
          setDomains(res.data.domain);
        })
    };
    const fetchAccount = async () => {
      if (localStorage.getItem("token")) {
        // verify token
        axios.get(`${process.env.REACT_APP_API_URL}/api/test/admin`, {
          headers: {
            'x-access-token': `${localStorage.getItem("token")}`
          }
        }).then((res) => {
          if (res.status === 200) {
            setIsAdmin(true);
          }
        }).catch((err) => {
          setIsAdmin(false);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("username");
          }
        });
      }
    };
    fetchAccount();
    fetchDomain();
  }, [isLoading, isScan]);


  function createNewDomain(newDomain) {
    setIsLoading(true);
    axios.post(`${process.env.REACT_APP_API_URL}/api/create`, {
      domain: newDomain
    }, {
      headers: {
        'x-access-token': `${localStorage.getItem("token")}`
      }
    })
      .then((res) => {
        toast({
          title: 'Create successfully',
          status: 'success',
          position: 'top-right',
          isClosable: true,
        })
      }).catch((err) => {
        toast({
          title: 'Create failed',
          status: 'error',
          position: 'top-right',
          isClosable: true,
        })
      }).finally(() => {
        setIsLoading(false);
        onClose();
      })
  }

  function deleteDomain(domain, retypeDomain) {
    if (domain === retypeDomain) {
      setIsLoading(true);
      axios.post(`${process.env.REACT_APP_API_URL}/api/delete`, {
        domain: domain
      }, {
        headers: {
          'x-access-token': `${localStorage.getItem("token")}`
        }
      })
        .then((res) => {
          toast({
            title: 'Delete successfully',
            status: 'success',
            position: 'top-right',
            isClosable: true,
          })
        }).catch((err) => {
          toast({
            title: 'Delete failed',
            status: 'error',
            position: 'top-right',
            isClosable: true,
          })
        }).finally(() => {
          setIsLoading(false);
          onClose();
        })
      }
  }

  async function scanOneDomain(domain) {
    onClose();
    setIsScan(true);
    setDomainScan(domain);
    await axios.post(`${process.env.REACT_APP_API_URL}/api/update`, {
      domain: domain
    }, {
      headers: {
        'x-access-token': `${localStorage.getItem("token")}`
      }
    })
    setProgressValue(100);
    toast({
      title: 'Scan successfully',
      status: 'success',
      position: 'top-right',
      isClosable: true,
    })
    setIsScan(false);
  }

  async function scanDomain() {
    setIsScan(true);
    for (let i = 0; i < domains.length; i++) {
      setDomainScan(domains[i].domain);
      await axios.post(`${process.env.REACT_APP_API_URL}/api/update`, {
        domain: domains[i].domain
      }, {
        headers: {
          'x-access-token': `${localStorage.getItem("token")}`
        }
      })
      setProgressValue((i + 1) / domains.length * 100);
    }
    toast({
      title: 'Scan successfully',
      status: 'success',
      position: 'top-right',
      isClosable: true,
    })
    setIsScan(false);
  }

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [action, setAction] = useState('');
  const [domain, setDomain] = useState('');

  function openModal(action, domain) {
    onOpen();
    setAction(action);
    setDomain(domain);
  }
  return (
    <Container maxW='container.xl' py={12}>
      <Heading as='h1' size='xl' textAlign='center' mb={8}>Dog Watcher Domain</Heading>
      {isAdmin && <Button colorScheme={'blue'} mb={3} onClick={openModal.bind(this, 'Add a new domain')} >Add new domain</Button>}
      {isAdmin && <SuperModal createNewDomain={createNewDomain} deleteDomain={deleteDomain} scanOneDomain={scanOneDomain} domain={domain} action={action} isLoading={isLoading} isOpen={isOpen} onClose={onClose} />}
      {isAdmin && <><Button colorScheme={'teal'} mb={3} ml={3} isLoading={isScan} onClick={scanDomain} >Scan</Button><br /><Text fontSize='sm' as='i' color={'ThreeDDarkShadow'}> Last scan: {new Date(domains[0]?.updateAt).toLocaleString('en-GB')}</Text></>}
      {isAdmin && isScan && <Text mb={3}>Scanning: {domainScan}</Text>}
      {isAdmin && isScan && <Progress mb={3} hasStripe isAnimated colorScheme='green' size='lg' value={progressValue} />}
      <TableContainer>
        <Table variant='simple'>
          <TableCaption>Danh sách domain</TableCaption>
          <Thead>
            <Tr>
              <Th>Domain</Th>
              <Th>IP</Th>
              <Th>SSL Days Remaining</Th>
              <Th>Status Code</Th>
              {isAdmin && <Th>Actions</Th>}
            </Tr>
          </Thead>
          <Tbody>
            {domains.map((domain, index) => (
              <Tr key={index}>
                <Td><Link href={'https://' + domain?.domain} isExternal>{domain?.domain}</Link></Td>
                <Td>{domain?.status.ip}</Td>
                <Td>{domain?.ssl.daysRemaining}</Td>
                <Td bgColor={domain?.status.statusCode !== 200 && 'orange'}>{domain?.status.statusCode}</Td>
                {isAdmin && <Td><Button colorScheme={'teal'} color={'white'} size={'sm'} mr={1} isLoading={isScan} onClick={openModal.bind(this, 'Scan domain', domain?.domain)}><FaSync /></Button><Button colorScheme={'red'} color={'white'} size={'sm'} onClick={openModal.bind(this, 'Delete domain', domain?.domain)}><FaTrash /></Button></Td>}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default App;
