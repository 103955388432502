import {
  Box,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  Image,
  Center,
} from '@chakra-ui/react';

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

export default function LargeWithLogoLeft() {
  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}>
      <Container as={Stack} maxW={'8xl'} py={10}>
        <SimpleGrid
          templateColumns={{ sm: '1fr 1fr', md: '1fr 1fr' }}
          spacing={8}>
          <Stack spacing={6}>
            <Center>
              <Image src='https://avatars.dicebear.com/api/bottts/dogwatcher.svg' height={100} width={100} alt='logo' />
            </Center>
            <Text textAlign="center" fontSize={'sm'}>
              DogWatcher Domain
            </Text>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Thông tin liên hệ</ListHeader>
            <Link href={'#'}>Address: Lầu 2, số 81 Nguyễn Hiền, KDC 91B, An Khánh, Ninh Kiều, Cần Thơ</Link>
            <Link href={'#'}>Email: info@deltalabsjsc.com</Link>
          </Stack>
        </SimpleGrid>
      </Container>
      <Container
          as={Stack}
          pb={4}
          textAlign="center">
          <Text>© 2022 Delta Labs Company. All Rights Reserved.</Text>
        </Container>
    </Box>
  );
}