import { ChakraProvider, theme } from '@chakra-ui/react';
import * as ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import App from './App';
import Login from "./routes/Login";
import Info from "./routes/Info";
import SSL from "./routes/SSL";
import NotFound from './components/NotFound';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

import Header from './components/Header';
import Footer from './components/Footer';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={
        <ChakraProvider theme={theme}>
          <Header />
          <App />
          <Footer />
        </ChakraProvider>
      } />
      <Route path="/login" element={
        <ChakraProvider theme={theme}>
          <Header />
          <Login />
          <Footer />
        </ChakraProvider>
      } />
      <Route path="/info" element={
        <ChakraProvider theme={theme}>
          <Header />
          <Info />
          <Footer />
        </ChakraProvider>
      } />
      <Route path="/ssl" element={
        <ChakraProvider theme={theme}>
          <Header />
          <SSL />
          <Footer />
        </ChakraProvider>
      } />
      <Route path="*" element={
        <ChakraProvider theme={theme}>
        <Header />
        <NotFound />
        <Footer />
      </ChakraProvider>
      } />
    </Routes>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
