import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Box,
  Text,
} from '@chakra-ui/react'
import React, { useState } from "react";

export default function SuperModal(props) {
  const [newDomain, setNewDomain] = useState('')

  return (
    <>
      <Modal isOpen={props.isOpen} onClose={props.onClose} size={'sm'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.action}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {props.action === 'Add a new domain' &&
              <FormControl>
                <FormLabel>Domain</FormLabel>
                <Input type='text' onChange={e => setNewDomain(e.target.value)} />
              </FormControl>
            }
            {props.action === 'Delete domain' &&
              // <Text>Delete <b>{props.domain}</b>?</Text>
              <FormControl>
                <FormLabel>Delete <b>{props.domain}</b>?</FormLabel>
                <Input type='text' placeholder={'Type '+props.domain+' to delete'} onChange={e => setNewDomain(e.target.value)} />
              </FormControl>
            }
            {props.action === 'Scan domain' &&
              <Text>Scan <b>{props.domain}</b>?</Text>
            }
          </ModalBody>

          <ModalFooter>
            {
              props.action === 'Add a new domain' &&
              <Button colorScheme='blue' size={'sm'} isLoading={props.isLoading} disabled={!/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(newDomain)} onClick={props.createNewDomain.bind(this, newDomain)}>
                Create
              </Button>
            }
            {props.action === 'Delete domain' &&
              <Box>
                <Button mr={2} size={'sm'} width={'80px'} isLoading={props.isLoading} disabled={props.domain !== newDomain} onClick={props.deleteDomain.bind(this, props.domain, newDomain)}>
                  Yes
                </Button>
                <Button colorScheme='blue' size={'sm'} width={'80px'} onClick={props.onClose}>
                  No
                </Button>
              </Box>
            }
            {props.action === 'Scan domain' &&
              <Box>
                <Button mr={2} size={'sm'} width={'80px'} isLoading={props.isLoading} onClick={props.scanOneDomain.bind(this, props.domain)}>
                  Yes
                </Button>
                <Button colorScheme='blue' size={'sm'} width={'80px'} onClick={props.onClose}>
                  No
                </Button>
              </Box>
            }
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}