import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Stack,
    Link,
    Button,
    Heading,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form'
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

export default function Login() {
    const navigate = useNavigate()
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchAccount = async () => {
            if (localStorage.getItem("token")) {
                // verify token
                axios.get(`${process.env.REACT_APP_API_URL}/api/test/user`, {
                    headers: {
                        'x-access-token': `${localStorage.getItem("token")}`
                    }
                }).then((res) => {
                    if (res.status === 200) {
                        navigate('/', { replace: true, state: { username: localStorage.getItem("username") } });
                    }
                }).catch((err) => {
                    if (err.response.status === 401) {
                        localStorage.removeItem("token");
                        localStorage.removeItem("username");
                    }
                });
            }
        };
        fetchAccount();
    }, [navigate]);

    function login() {
        setIsLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL}/api/auth/signin`, {
            username: username,
            password: password
        }).then((response) => {
            localStorage.setItem("token", response.data.accessToken);
            localStorage.setItem("username", response.data.username);
            navigate('/', { replace: true, state: { username: response.data.username } });
        }).catch((error) => {
            if (error.response.status === 404 || error.response.status === 401) {
                setError('Invalid username or password');
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const {handleSubmit} = useForm()
    
    function onSubmit(values) {
        login();
    }
      
    return (
        <Flex
            minH={'80vh'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Stack align={'center'}>
                    <Heading fontSize={'4xl'}>Sign in to your account</Heading>
                    <Text fontSize={'lg'} color={'gray.600'}>
                        to enjoy all of our cool <Link color={'blue.400'}>features</Link> ✌️
                    </Text>
                </Stack>
                <Box
                    rounded={'lg'}
                    bg={useColorModeValue('white', 'gray.700')}
                    boxShadow={'lg'}
                    p={8}>
                    <Stack spacing={4}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FormControl id="username">
                                <FormLabel>Username</FormLabel>
                                <Input type="username" onChange={e => setUsername(e.target.value)} required />
                            </FormControl>
                            <FormControl id="password">
                                <FormLabel>Password</FormLabel>
                                <Input type="password" onChange={e => setPassword(e.target.value)} required />
                            </FormControl>
                            <Text color={'red.500'}>{error}</Text>
                            <Stack spacing={10}>
                                <Stack
                                    direction={{ base: 'column', sm: 'row' }}
                                    align={'start'}
                                    justify={'space-between'}>
                                    <Checkbox>Remember me</Checkbox>
                                    <Link color={'blue.400'}>Forgot password?</Link>
                                </Stack>
                                <Button
                                    bg={'blue.400'}
                                    color={'white'}
                                    _hover={{
                                        bg: 'blue.500',
                                    }}
                                    type='submit'
                                    isLoading={isLoading}>
                                    Sign in
                                </Button>
                            </Stack>
                        </form>
                    </Stack>
                </Box>
            </Stack>
        </Flex>
    );
}