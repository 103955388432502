import { Center } from "@chakra-ui/react";
export default function NotFound() {
  return (
    <Center bgColor={'#F8F8F8'}>
      <img
        src="https://www.pngitem.com/pimgs/m/561-5616833_image-not-found-png-not-found-404-png.png"
        alt="not-found"
      />
    </Center>
  );
}
