import {
  Container,
  Heading,
  Link
} from '@chakra-ui/react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import React, { useState, useEffect } from "react";
import axios from 'axios';

export default function Info() {
  const [domains, setDomains] = useState([]);

  useEffect(() => {
    const fetchAccount = async () => {
      axios.get(`${process.env.REACT_APP_API_URL}/api/domain`)
        .then((res) => {
          setDomains(res.data.domain);
        })
    };
    fetchAccount();
  }, []);

  return (
    <Container maxW='container.xl' py={12}>
      <Heading as='h1' size='xl' textAlign='center' mb={12}>Thông tin Domain</Heading>
      <TableContainer>
        <Table variant='simple'>
          <TableCaption>Thông tin domain</TableCaption>
          <Thead>
            <Tr>
              <Th>Domain</Th>
              <Th>Name Server</Th>
              <Th>Created Date</Th>
              <Th>Expiry Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            {domains.map((domain, index) => (
              <Tr key={index}>
                <Td><Link href={'https://' + domain.domain} isExternal>{domain.domain}</Link></Td>
                <Td>{domain.info && domain.info["Name Server"].length > 0 && JSON.stringify(domain.info["Name Server"]).replace(/"/g, '').slice(1, -1)}</Td>
                <Td>{domain.info && domain.info["Created Date"] && new Date(domain.info["Created Date"]).toLocaleDateString('en-GB')}</Td>
                <Td>{domain.info && domain.info["Expiry Date"] && new Date(domain.info["Expiry Date"]).toLocaleDateString('en-GB')}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Container>
  );
}
